import React, {ReactNode, useState} from 'react';
import ReactDOM from 'react-dom/client';
import App from 'src/App';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import {useAnalyticsHandler} from '@fce-bees/fce-common-components';

const analyticsScriptSrc = import.meta.env.VITE_AA_SCRIPT_SRC;
const isMocksEnabled = import.meta.env.VITE_CONDITIONED_DOC_MOCKS_ENABLED == 'true';

const AnalyticsProvider = ({children}: {children: ReactNode}) => {
    const [isAnalyticsLoaded, setIsAnalyticsLoaded] = useState(!analyticsScriptSrc);

    useAnalyticsHandler({
        scriptSrc: analyticsScriptSrc,
        onAnalyticsLoaded: () => setTimeout(() => setIsAnalyticsLoaded(true)),
    });

    return !isAnalyticsLoaded ? null : children;
};

function startMockingIfEnabled() {
    if (!isMocksEnabled) return Promise.resolve();

    return import('./mocks/browser')
        .then(({worker}) => worker.start({onUnhandledRequest: 'warn'}))
        .then(() => new Promise((resolve) => setTimeout(resolve, 3000)));
}

startMockingIfEnabled().then(() => {
    ReactDOM.createRoot(document.getElementById('root')!).render(
        <React.StrictMode>
            <AnalyticsProvider>
                <BrowserRouter>
                    <Routes>
                        <Route path="/*" element={<App />} />
                    </Routes>
                </BrowserRouter>
            </AnalyticsProvider>
        </React.StrictMode>
    );
});
